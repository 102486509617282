import { InviteStatusEnum } from '@/types'
import type { BadgeVariants } from '@/ui'

export const INVITE_STATUS_VARIANTS: Record<InviteStatusEnum, BadgeVariants['variant']> = {
  [InviteStatusEnum.CREATED]: 'orange',
  [InviteStatusEnum.SENT]: 'orange',
  [InviteStatusEnum.ACCEPTED]: 'green',
  [InviteStatusEnum.REJECTED]: 'red',
  [InviteStatusEnum.EXPIRED]: 'yellow',
  [InviteStatusEnum.CANCELLED]: 'secondary',
}

export const INVITE_STATUS_TITLES: Record<InviteStatusEnum, string> = {
  [InviteStatusEnum.CREATED]: 'Pending',
  [InviteStatusEnum.SENT]: 'Pending',
  [InviteStatusEnum.ACCEPTED]: 'Accepted',
  [InviteStatusEnum.REJECTED]: 'Rejected',
  [InviteStatusEnum.EXPIRED]: 'Expired',
  [InviteStatusEnum.CANCELLED]: 'Cancelled',
}
