import { computed, ComputedRef, isRef, unref } from 'vue'
import { useFetch, UseFetchOptions, UseFetchReturn } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import { useAuth0 } from '@auth0/auth0-vue'

import type { PaginationType, MetaType, ApiResponseType } from '@/types'
import { PER_PAGE } from '@/constants'
import { appConfig } from '@/config'
import { useToast } from '@/ui'
import { formatedField } from '@/utils'

export function checkIsFullUrl(url: string) {
  return /^https?:\/\//i.test(url)
}

function computeUrl(value: string) {
  return checkIsFullUrl(value) ? value : appConfig.api.url + value
}

export function ensureHttps(url?: string) {
  if (!url) return url

  const trimmedUrl = url.trim()

  return checkIsFullUrl(trimmedUrl) ? trimmedUrl : `https://${trimmedUrl}`
}

export function getApiPaginationData(meta: MetaType): PaginationType {
  const pageIndex = meta.current_page - 1 || 0
  const pageSize = meta.per_page || PER_PAGE
  const totalItems = meta.total || 0
  const pageCount = meta.last_page || 1

  return { pageIndex, pageSize, totalItems, pageCount }
}

export const validationErrorHandler = (errors: Record<string, string>) => {
  const { toast } = useToast()

  const entries = Object.entries(errors)

  const message = entries.map(([field, msg]) => `${formatedField(field)}: ${msg}`).join(', ')

  const firstErrorFieldName = entries[0]?.[0]

  toast({
    title: 'Validation Error',
    description: message,
    variant: 'warning',
  })

  if (firstErrorFieldName) {
    const element = document.querySelector(`[name="${firstErrorFieldName}"]`)
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    if (element instanceof HTMLElement) {
      element.focus({ preventScroll: true })
    }
  }
}

type UseApiPropsType = {
  url: string | ComputedRef<string>
  method: string
  parseType?: string
  fetchOptions?: RequestInit
  useFetchOptions?: UseFetchOptions
}

export function useApi() {
  const { getAccessTokenSilently } = useAuth0()

  function apiFetch<T>({
    url,
    method,
    parseType,
    fetchOptions = {},
    useFetchOptions = {},
  }: UseApiPropsType): ApiResponseType<T> {
    const urlStr = isRef(url) ? computed(() => computeUrl(unref(url))) : computeUrl(url)
    let parsedAs = parseType || 'json'

    if (method === 'DELETE') {
      parsedAs = 'text'
    }

    const { data, isFetching, isFinished, execute, error } = useFetch<UseFetchReturn<T>>(
      urlStr,
      {
        method,
        ...fetchOptions,
      },
      {
        async beforeFetch({ options }) {
          if (getAccessTokenSilently) {
            const accessToken = await getAccessTokenSilently()
            options.headers = { ...options.headers, Authorization: `Bearer ${accessToken}` }
          }
          return { options }
        },
        afterFetch(ctx) {
          if (ctx.response.ok && method === 'GET' && ctx.data?.meta) {
            ctx.data.pagination = getApiPaginationData(ctx.data.meta)
          }

          return ctx
        },
        updateDataOnError: true,
        onFetchError(ctx) {
          const { error, data, response } = ctx
          let message = 'Unknown error'

          if (response?.status === 422) {
            message = 'Validation error: ' + data?.message
          } else if (data?.message) {
            message = data?.message
          } else if (error?.message) {
            message = error?.message
          }
          if (message === 'signal is aborted without reason') {
            return ctx
          }

          if (response?.status !== 422) {
            Sentry.captureException(error, {
              contexts: {
                response: {
                  status: response?.status,
                  data: data,
                },
                request: {
                  url: url,
                  method: method,
                },
              },
              level: 'error',
            })
          }

          const { toast } = useToast()

          toast({
            title: `Error. ${response?.status ? 'Status code: ' + response?.status : ''}`,
            description: message,
            variant: 'destructive',
          })

          return ctx
        },
        ...useFetchOptions,
      }
    )[parsedAs]()

    return {
      execute,
      error: computed(() => error.value),
      isFetching: computed(() => isFetching.value),
      isFinished: computed(() => isFinished.value),
      responseData: computed(() => {
        if (parseType === 'text') {
          return {}
        }

        if (parseType !== 'blob' && data.value?.data) {
          return data.value.data
        }

        return data.value
      }),
      pagination: computed(() => (parseType === 'json' ? data.value?.pagination : {})),
    }
  }

  return { apiFetch }
}
