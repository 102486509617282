import { IconNameType, ProjectFileType } from '@/types'

export enum FeedTypeEnum {
  INQUIRY = 'inquiry',
  CALL = 'call',
  MESSAGE = 'message',
  EVENT = 'event',
  NOTE = 'note',
  CALL_NOTE = 'call_note',
  FILE = 'file',
  CLIENT_CHANGES = 'client_changes',
}

export type ConversationTranscriptType = {
  phrase: string
  speaker: string
  start: number
}

export type FeedCommentType = {
  id: string
  user_id: number
  body: string
  created_at: string
  updated_at: string
}

export type FeedUserType = {
  id: number
  name: string
  email: string
}

export type FeedType = {
  id: string
  type: FeedTypeEnum
  created_at: string
  updated_at: string
  full_name: string
  email: string
  phone_number: string
  phone_string?: string
  comments?: FeedCommentType[]
  user?: FeedUserType

  // FeedTypeEnum.INQUIRY | FeedTypeEnum.MESSAGE
  message?: string

  // FeedTypeEnum.INQUIRY | FeedTypeEnum.EVENT
  customer_services?: string[]

  // FeedTypeEnum.INQUIRY
  source?: string
  location?: string[]
  procedures?: string[]
  price?: string[]

  // FeedTypeEnum.CALL
  prior_calls?: number
  total_calls?: number
  duration?: number
  recording_duration?: number
  device_type?: string
  direction?: string
  recording?: string
  recording_player?: string
  timeline_url?: string
  waveforms?: string[]
  conversational_transcript?: ConversationTranscriptType[]
  call_time?: string
  conversation_time?: string
  files?: ProjectFileType[]

  // FeedTypeEnum.EVENT
  event_name?: string
  event_status?: string
  cancel_url?: string
  reschedule_url?: string
  start_time?: string
  end_time?: string
  rescheduled?: boolean
  guests?: string[]
  payment?: string
  questions_and_answers?: string[]
  cancellation?: string

  // FeedTypeEnum.NOTE | FeedTypeEnum.CALL_NOTE
  note?: string

  // FeedTypeEnum.NOTE
  clear_note?: string

  // FeedTypeEnum.CALL_NOTE
  datetime?: string

  // FeedTypeEnum.CLIENT_CHANGES
  changed_class?: string[]
  new?: Record<string, unknown>
  old?: Record<string, unknown>

  // FeedTypeEnum.MESSAGE
  thread_id?: string
  message_id?: string
  sender?: string
  sender_name?: string
  sender_email?: string
  client_number?: string
  company_number?: string

  // Generic
  messageCount?: number
}

export type ActivityDataType = {
  date: string
  icon: IconNameType
  title: string
  prefix: string
  time: string
  source?: string
  sourceType?: string
}

export type FeedFilterType = {
  created_at?: { start: string; end: string }
  selectedTypes: FeedTypeEnum[]
  direction: 'desc' | 'asc'
}
