import { defineStore } from 'pinia'
import { computed, ComputedRef, Ref, ref, watch } from 'vue'

import { getRoles, getUserRoles } from '@/api'
import { PermissionType, SelectItemType } from '@/types'

export const useRolesStore = defineStore('roles', () => {
  const { responseData: rolesData, execute: executeRoles, isFetching: isFetchingRoles } = getRoles()

  if (!rolesData.value && !isFetchingRoles.value) {
    executeRoles()
  }

  const rolesOptions: ComputedRef<SelectItemType[]> = computed(
    () =>
      rolesData.value?.map(role => ({
        id: role.id,
        name: role.name,
      })) || []
  )

  const permissions: Ref<PermissionType | undefined> = ref()

  const { responseData, execute: fetchPermissions, error, isFetching, isFinished } = getUserRoles()

  if (!isFetching.value && !permissions.value) {
    fetchPermissions()
  }

  watch(isFinished, () => {
    if (isFinished.value && !error.value && responseData.value) {
      let uniquePermissions = {} as PermissionType

      responseData.value?.forEach(role => {
        Object.keys(role.permissions).forEach(permission => {
          if (!uniquePermissions[permission]) {
            uniquePermissions[permission] = role.permissions[permission]
          }
        })
      })

      permissions.value = uniquePermissions
    }
  })

  return {
    rolesOptions,
    isFetchingRoles,
    permissions,
    isFetching,

    fetchPermissions,
  }
})
