import { computed, Ref } from 'vue'

import { useApi } from '@/utils'
import { RoleAssignmentType, RoleType } from '@/types'

export function getRoles() {
  const { apiFetch } = useApi()

  return apiFetch<RoleType[]>({
    url: '/roles',
    method: 'GET',
    useFetchOptions: { immediate: false },
  })
}

export function getRolesByClinicId(clinicId: Ref<number>) {
  const { apiFetch } = useApi()

  const url = computed(() => `/websites/${clinicId.value}/roles/assignments`)

  return apiFetch<RoleAssignmentType>({
    url,
    method: 'GET',
    useFetchOptions: { immediate: false },
  })
}

export function getUserRoles() {
  const { apiFetch } = useApi()

  return apiFetch<RoleType[]>({
    url: '/users/roles/assignments',
    method: 'GET',
    useFetchOptions: { immediate: false },
  })
}

export function useRolesApi() {
  const { apiFetch } = useApi()

  const updateUserRole = (clinicId: number, formData: FormData) => {
    return apiFetch({
      url: `/websites/${clinicId}/roles/assignments`,
      method: 'POST',
      fetchOptions: { body: formData },
    })
  }

  const removeUserFromClinic = (clinicId: number, userId: number) => {
    return apiFetch({
      url: `/websites/${clinicId}/users/${userId}/remove`,
      method: 'POST',
      parseType: 'text',
    })
  }

  return { removeUserFromClinic, updateUserRole }
}
