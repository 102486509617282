import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import {
  CalendarViewEnum,
  EventBillingTypeEnum,
  EventStatusEnum,
  EventVisitTypeEnum,
  IconNameType,
  SelectItemType,
} from '@/types'

export const DAYS_OF_WEEK = {
  Sunday: {
    name: 'Sunday',
    short: 'Sun',
    order: 0,
  },
  Monday: {
    name: 'Monday',
    short: 'Mon',
    order: 1,
  },
  Tuesday: {
    name: 'Tuesday',
    short: 'Tue',
    order: 2,
  },
  Wednesday: {
    name: 'Wednesday',
    short: 'Wed',
    order: 3,
  },
  Thursday: {
    name: 'Thursday',
    short: 'Thu',
    order: 4,
  },
  Friday: {
    name: 'Friday',
    short: 'Fri',
    order: 5,
  },
  Saturday: {
    name: 'Saturday',
    short: 'Sat',
    order: 6,
  },
}

export const START_TIME = '09:00'
export const END_TIME = '17:00'

export const TIME_OPTIONS = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
]

export const MONTH_OPTIONS: SelectItemType[] = [
  { id: '1', name: 'January' },
  { id: '2', name: 'February' },
  { id: '3', name: 'March' },
  { id: '4', name: 'April' },
  { id: '5', name: 'May' },
  { id: '6', name: 'June' },
  { id: '7', name: 'July' },
  { id: '8', name: 'August' },
  { id: '9', name: 'September' },
  { id: '10', name: 'October' },
  { id: '11', name: 'November' },
  { id: '12', name: 'December' },
]

export const CALENDAR_VIEW_TITLES: Record<CalendarViewEnum, string> = {
  [CalendarViewEnum.DAY]: 'Today',
  [CalendarViewEnum.WEEK]: 'This Week',
  [CalendarViewEnum.MONTH]: 'This Month',
}

export const CALENDAR_VIEWS_OPTIONS: { value: CalendarViewEnum; label: string; icon: IconNameType }[] = [
  { value: CalendarViewEnum.DAY, label: 'Day', icon: 'WindowIcon' },
  { value: CalendarViewEnum.WEEK, label: 'Week', icon: 'ViewColumnsIcon' },
  { value: CalendarViewEnum.MONTH, label: 'Month', icon: 'TableCellsIcon' },
]

export const DEFAULT_CALENDAR_OPTIONS = {
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  initialView: CalendarViewEnum.WEEK as string,
  firstDay: 1,
  headerToolbar: false as const,
  allDaySlot: false,
  displayEventEnd: true,
  eventTimeFormat: { hour: 'numeric', minute: '2-digit', hour12: true } as const,
  expandRows: true,
  nowIndicator: true,
  selectable: true,
}

export const EventBillingTypeTitles: Record<EventBillingTypeEnum, string> = {
  [EventBillingTypeEnum.INSURANCE]: 'Insurance',
  [EventBillingTypeEnum.UNSURE]: 'Unsure',
  [EventBillingTypeEnum.NON_BILLABLE]: 'Non-Billable',
  [EventBillingTypeEnum.ELECTIVE]: 'Elective',
}

export const EventVisitTypeTitles: Record<EventVisitTypeEnum, string> = {
  [EventVisitTypeEnum.VIRTUAL_CONSULTATION]: 'Virtual Consultation',
  [EventVisitTypeEnum.VIRTUAL_FOLLOW_UP]: 'Virtual Follow Up',
  [EventVisitTypeEnum.PHONE_CONSULTATION]: 'Phone Consultation',
  [EventVisitTypeEnum.CONSULTATION]: 'Consultation',
  [EventVisitTypeEnum.PRE_TREATMENT]: 'Pre Treatment',
  [EventVisitTypeEnum.PROCEDURE_TREATMENT]: 'Procedure Treatment',
  [EventVisitTypeEnum.FOLLOW_UP]: 'Follow Up',
}

export const EventColorClasses: Record<
  EventVisitTypeEnum,
  {
    hoverBg?: string
    border?: string
    text?: string
  }
> = {
  [EventVisitTypeEnum.VIRTUAL_CONSULTATION]: {
    hoverBg: 'hover:bg-blue-50',
    border: 'border-blue-400',
    text: 'text-blue-500',
  },
  [EventVisitTypeEnum.VIRTUAL_FOLLOW_UP]: {
    hoverBg: 'hover:bg-purple-50',
    border: 'border-purple-400',
    text: 'text-purple-500',
  },
  [EventVisitTypeEnum.PHONE_CONSULTATION]: {
    hoverBg: 'hover:bg-lime-50',
    border: 'border-lime-400',
    text: 'text-lime-500',
  },
  [EventVisitTypeEnum.CONSULTATION]: {
    hoverBg: 'hover:bg-green-50',
    border: 'border-green-400',
    text: 'text-green-500',
  },
  [EventVisitTypeEnum.PRE_TREATMENT]: {
    hoverBg: 'hover:bg-pink-50',
    border: 'border-pink-400',
    text: 'text-pink-500',
  },
  [EventVisitTypeEnum.PROCEDURE_TREATMENT]: {
    hoverBg: 'hover:bg-indigo-50',
    border: 'border-indigo-400',
    text: 'text-indigo-500',
  },
  [EventVisitTypeEnum.FOLLOW_UP]: {
    hoverBg: 'hover:bg-yellow-50',
    border: 'border-yellow-400',
    text: 'text-yellow-500',
  },
}

export const EventStatusTitles: Record<EventStatusEnum, string> = {
  [EventStatusEnum.SCHEDULED]: 'Scheduled',
  [EventStatusEnum.CONFIRMED]: 'Confirmed',
  [EventStatusEnum.CANCELLED]: 'Cancelled',
  [EventStatusEnum.MESSAGE_LEFT]: 'Message Left',
  [EventStatusEnum.WAITLISTED]: 'Waitlisted',
  [EventStatusEnum.NO_SHOW]: 'No Show',
  [EventStatusEnum.RESERVED]: 'Reserved',
  [EventStatusEnum.RESCHEDULED]: 'Rescheduled',
}
