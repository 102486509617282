import { FormValueType, IconNameType } from '@/types'
import { useToast } from '@/ui'

export const dataToFormData = (data: Record<string, FormValueType>): FormData => {
  const formData = new FormData()

  const appendFormData = (formData: FormData, key: string, value: FormValueType) => {
    if (value === undefined || value === null) {
      return
    }

    if (typeof value === 'object' && !(value instanceof File)) {
      Object.entries(value as Record<string, object>).forEach(([nestedKey, nestedValue]) => {
        if (nestedValue === undefined || nestedValue === null) {
          return
        }
        appendFormData(formData, `${key}[${nestedKey}]`, nestedValue)
      })
    } else if (typeof value === 'boolean') {
      formData.append(key, value ? '1' : '0')
    } else {
      formData.append(key, value as string | Blob)
    }
  }

  Object.entries(data).forEach(([key, value]) => {
    appendFormData(formData, key, value)
  })

  return formData
}

export const getInitials = (obj = {}) => {
  let { name, first_name = '', last_name = '' } = obj as Record<string, string>

  if (!first_name && !last_name && name) {
    const arr = name.split(' ')
    first_name = arr[0] || ''
    last_name = arr[1] || ''
  }

  return `${first_name.charAt(0)}${last_name.charAt(0)}`
}

export const getName = (obj = {}, withDefault = true) => {
  const { name, first_name, middle_name, last_name } = obj as Record<string, string>

  let result = [first_name, middle_name, last_name].filter(part => part && part.trim() !== '').join(' ') || name || ''

  if (!result && withDefault) {
    result = 'unknown'
  }

  return result
}

export const getFileName = (url: string): string => {
  try {
    const parsedUrl = new URL(url)
    const encodedName = parsedUrl.pathname.split('/').pop() || ''
    return decodeURIComponent(encodedName)
  } catch (error) {
    const { toast } = useToast()
    toast({
      title: 'Invalid URL or failed to decode filename:',
      description: error instanceof Error ? error.message : undefined,
      variant: 'destructive',
    })
    const arr = url.split('/')
    return arr[arr.length - 1]
  }
}

export const getFileIcon = (fileName: string): IconNameType => {
  const fileExtension = fileName.split('.').pop()
  switch (fileExtension) {
    case 'pdf':
    case 'doc':
    case 'docx':
      return 'DocumentTextIcon'
    case 'xls':
    case 'xlsx':
    case 'ppt':
    case 'pptx':
      return 'DocumentChartBarIcon'
    case 'jpeg':
    case 'png':
    case 'jpg':
    case 'svg':
      return 'PhotoIcon'
    case 'gif':
      return 'GifIcon'
    default:
      return 'DocumentIcon'
  }
}

export const formatedField = (field: string): string => {
  return field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
}
