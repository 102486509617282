import { ClinicType, UserType } from '@/types'

export type PermissionType = Record<PermissionTypeEnums, boolean>
export type PermissionsByClinicType = Record<string, PermissionType>

export type RoleType = {
  id: number
  name: string
  description: string
  permissions: PermissionType
  is_available: boolean
  is_editable: boolean
  created_at?: string
  updated_at?: string
  deleted_at?: string | null
}

export type UserRoleType = {
  id: number
  name: string
  roles: number[]
}

export type RoleAssignmentType = {
  users: UserRoleType[]
  available_roles: RoleType[]
}

export enum PermissionTypeEnums {
  VIEW_APPOINTMENTS = 'view-appointments',
  CREATE_APPOINTMENTS = 'create-appointments',
  EDIT_APPOINTMENTS = 'edit-appointments',
  DELETE_APPOINTMENTS = 'delete-appointments',
  VIEW_PATIENTS = 'view-patients',
  CREATE_PATIENTS = 'create-patients',
  EDIT_PATIENTS = 'edit-patients',
  DELETE_PATIENTS = 'delete-patients',
  VIEW_MESSAGES = 'view-messages',
  CREATE_MESSAGES = 'create-messages',
  VIEW_LEADS = 'view-leads',
  EDIT_LEADS = 'edit-leads',
  DELETE_LEADS = 'delete-leads',
  VIEW_FEEDS = 'view-feeds',
  CREATE_FEEDS = 'create-feeds',
  EDIT_FEEDS = 'edit-feeds',
  VIEW_EHR_ROLES = 'view-ehr-roles',
  ASSIGN_EHR_ROLES = 'assign-ehr-roles',
  CREATE_EHR_ROLE = 'create-ehr-role',
  EDIT_EHR_ROLE = 'edit-ehr-role',
  DELETE_EHR_ROLE = 'delete-ehr-role',
  INVITE_USERS = 'invite-users',
  VIEW_USERS = 'view-users',
  EDIT_USERS = 'edit-users',
  REMOVE_USERS = 'remove-users',
  VIEW_CLINICS = 'view-clinics',
  EDIT_CLINICS = 'edit-clinics',
}

export enum InviteStatusEnum {
  CREATED = 'created',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
}

export type InviteType = {
  id: string
  email: string
  token: string
  status: InviteStatusEnum
  role: RoleType
  website: ClinicType
  user: UserType
  invited_by: UserType
  created_at: string
}
