import {
  parse,
  isValid,
  setHours,
  setMinutes,
  setSeconds,
  format,
  differenceInMinutes,
  differenceInSeconds,
  formatDistanceToNow,
} from 'date-fns'

import { PeriodType, TimePickerType } from '@/types'

export const getFormattedTime = (date: string) =>
  differenceInMinutes(new Date(), date) < 60
    ? formatDistanceToNow(date, { addSuffix: true, includeSeconds: true })
    : format(date, 'h:mm a')

export const isLessThanPeriod = (dateOne: string, dateSecond: string, period: number = 60) =>
  differenceInSeconds(dateOne, dateSecond) < period

type GetValidNumberConfig = { max: number; min?: number; loop?: boolean }

/**
 * Validates if the input string is a valid hour in 24-hour format (00-23)
 */
export function isValidHour(value: string): boolean {
  const parsedDate = parse(value, 'HH', new Date())
  return isValid(parsedDate) && Number(value) >= 0 && Number(value) <= 23
}

/**
 * Validates if the input string is a valid hour in 12-hour format (01-12)
 */
export function isValid12Hour(value: string): boolean {
  const parsedDate = parse(value, 'hh', new Date())
  return isValid(parsedDate) && Number(value) >= 1 && Number(value) <= 12
}

/**
 * Validates if the input string is a valid minute or second (00-59)
 */
export function isValidMinuteOrSecond(value: string): boolean {
  const parsedDate = parse(value, 'mm', new Date())
  return isValid(parsedDate) && Number(value) >= 0 && Number(value) <= 59
}

/**
 * Ensures the number is within the specified range, optionally looping.
 */
export function getValidNumber(value: string, { max, min = 0, loop = false }: GetValidNumberConfig): string {
  let numericValue = parseInt(value, 10)

  if (!isNaN(numericValue)) {
    if (!loop) {
      numericValue = Math.max(min, Math.min(max, numericValue))
    } else {
      const range = max - min + 1
      numericValue = ((((numericValue - min) % range) + range) % range) + min
    }
    return numericValue.toString().padStart(2, '0')
  }

  return '00'
}

/**
 * Sets the minutes on a Date object.
 */
export function setMinutesToDate(date: Date, value: string): Date {
  const minutes = getValidNumber(value, { max: 59 })
  return setMinutes(date, parseInt(minutes, 10))
}

/**
 * Sets the seconds on a Date object.
 */
export function setSecondsToDate(date: Date, value: string): Date {
  const seconds = getValidNumber(value, { max: 59 })
  return setSeconds(date, parseInt(seconds, 10))
}

/**
 * Sets the hours on a Date object.
 */
export function setHoursToDate(date: Date, value: string): Date {
  const hours = getValidNumber(value, { max: 23 })
  return setHours(date, parseInt(hours, 10))
}

/**
 * Sets the hours on a Date object using 12-hour format.
 */
export function set12HoursToDate(date: Date, value: string, period: PeriodType): Date {
  const hours = parseInt(getValidNumber(value, { min: 1, max: 12 }), 10)
  const convertedHours = convert12HourTo24Hour(hours, period)
  return setHours(date, convertedHours)
}

/**
 * Converts 12-hour format to 24-hour format.
 */
export function convert12HourTo24Hour(hour: number, period: PeriodType): number {
  if (period === 'PM' && hour < 12) {
    return hour + 12
  }
  if (period === 'AM' && hour === 12) {
    return 0
  }
  return hour
}

/**
 * Displays the hour in 12-hour format.
 */
export function display12HourValue(hours: number): string {
  const twelveHour = hours % 12 || 12
  return twelveHour < 10 ? `0${twelveHour}` : `${twelveHour}`
}

/**
 * Sets the date based on the picker type.
 */
export function setDateByType(date: Date, value: string, type: TimePickerType, period?: PeriodType): Date {
  switch (type) {
    case 'minutes':
      return setMinutesToDate(date, value)
    case 'seconds':
      return setSecondsToDate(date, value)
    case 'hours':
      return setHoursToDate(date, value)
    case '12hours':
      if (!period) return date
      return set12HoursToDate(date, value, period)
    default:
      return date
  }
}

/**
 * Retrieves the date part based on the picker type.
 */
export function getDateByType(date: Date, type: TimePickerType): string {
  switch (type) {
    case 'minutes':
      return format(date, 'mm')
    case 'seconds':
      return format(date, 'ss')
    case 'hours':
      return format(date, 'HH')
    case '12hours':
      return display12HourValue(date.getHours())
    default:
      return '00'
  }
}

/**
 * Handles arrow key adjustments based on type.
 */
export function getArrowByType(value: string, step: number, type: TimePickerType): string {
  const numericValue = parseInt(value, 10) + step
  switch (type) {
    case 'minutes':
    case 'seconds':
      return getValidNumber(String(numericValue), { min: 0, max: 59, loop: true })
    case 'hours':
      return getValidNumber(String(numericValue), { min: 0, max: 23, loop: true })
    case '12hours':
      return getValidNumber(String(numericValue), { min: 1, max: 12, loop: true })
    default:
      return '00'
  }
}
