import { NavigationType, PermissionTypeEnums } from '@/types'
import { HOME_PAGE_GUEST } from '@/constants'

export const MAIN_MENU_ITEMS: NavigationType = {
  Navigation: [
    {
      text: 'Appointments',
      to: '/appointments',
      icon: 'CalendarDaysIcon',
      permission: PermissionTypeEnums.VIEW_APPOINTMENTS,
    },
    {
      text: 'Patients',
      to: '/patients',
      icon: 'UsersIcon',
      permission: PermissionTypeEnums.VIEW_PATIENTS,
    },
    {
      text: 'Messages',
      to: '/messages',
      icon: 'ChatBubbleLeftRightIcon',
      permission: PermissionTypeEnums.VIEW_MESSAGES,
    },
  ],
  Tools: [
    {
      text: 'User Roles',
      to: '/user-roles',
      icon: 'WrenchScrewdriverIcon',
      permission: PermissionTypeEnums.VIEW_EHR_ROLES,
    },
    {
      text: 'My Invitations',
      to: HOME_PAGE_GUEST,
      icon: 'ClipboardDocumentListIcon',
    },
  ],
}
