import { createWebHistory, createRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'
import { watchEffect } from 'vue'
import { storeToRefs } from 'pinia'

import { HOME_PAGE, HOME_PAGE_GUEST } from '@/constants'
import { useRolesStore } from '@/stores'
import { PermissionTypeEnums } from '@/types'

const routes = [
  {
    path: '/patients',
    meta: { breadcrumb: 'Patients', permission: PermissionTypeEnums.VIEW_PATIENTS },
    children: [
      {
        path: '',
        component: () => import('@/pages/patient-list/PatientsListPage.vue'),
      },
      {
        path: ':id',
        name: 'PatientDetails',
        component: () => import('@/pages/patient/PatientViewPage.vue'),
        meta: { breadcrumb: 'Patient Details', permission: PermissionTypeEnums.VIEW_PATIENTS },
      },
    ],
  },
  {
    path: '/messages',
    component: () => import('@/pages/messages/MessagesViewPage.vue'),
    meta: { breadcrumb: 'Messages', permission: PermissionTypeEnums.VIEW_MESSAGES },
  },
  {
    path: '/appointments',
    name: 'Appointments',
    component: () => import('@/pages/appointments/AppointmentsViewPage.vue'),
    meta: { breadcrumb: 'Appointments', permission: PermissionTypeEnums.VIEW_APPOINTMENTS },
  },
  {
    path: '/user-roles',
    component: () => import('@/pages/user-roles/UserRolesListPage.vue'),
    meta: { breadcrumb: 'User Roles', permission: PermissionTypeEnums.INVITE_USERS },
  },
  {
    path: HOME_PAGE_GUEST,
    name: 'MyInvites',
    component: () => import('@/pages/my-invites/UserInvitesListPage.vue'),
    meta: { breadcrumb: 'My Invitations' },
  },
  { path: '/callback', name: 'Callback', component: () => import('@/pages/auth/AuthCallback.vue') },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => null },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0() || {}

  await new Promise(resolve => {
    watchEffect(() => {
      if (!isLoading.value) resolve(null)
    })
  })

  if (to.name === 'Callback') {
    if (to.query.error) {
      await loginWithRedirect({
        appState: { targetUrl: from.fullPath === '/' ? HOME_PAGE : from.fullPath },
      })
      return next(false)
    }

    return next()
  }

  if (!isAuthenticated.value) {
    await loginWithRedirect({
      authorizationParams: {
        prompt: 'none',
      },
      appState: { targetUrl: to.fullPath },
    })
    return next(false)
  }

  const rolesStore = useRolesStore()
  const { permissions, isFetching } = storeToRefs(rolesStore)

  await new Promise(resolve => {
    watchEffect(() => {
      if (!isFetching.value) resolve(null)
    })
  })
  const hasPermission = !to.meta.permission || permissions.value?.[to.meta.permission]
  const homePage = hasPermission ? HOME_PAGE : HOME_PAGE_GUEST

  if (!hasPermission || to.name === 'NotFound') {
    return next(homePage)
  }

  next()
})

export default router
