import { arraySchema, idSchema, nativeEnumSchema, numberSchema, stringSchema, zObject } from '@/schemas'
import { EventBillingTypeEnum, EventStatusEnum, EventVisitTypeEnum } from '@/types'
import { isLessThanPeriod } from '@/utils'

export const calendarSchema = zObject({
  start_at: stringSchema.datetime(),
  end_at: stringSchema.datetime(),
  location_id: numberSchema.optional(),
  patient_id: idSchema,
  bill_type: nativeEnumSchema(EventBillingTypeEnum),
  visit_type: nativeEnumSchema(EventVisitTypeEnum),
  status: nativeEnumSchema(EventStatusEnum),
  participants: arraySchema(stringSchema).optional(),
}).refine(data => !isLessThanPeriod(data.end_at, data.start_at, 0), {
  message: 'End date must be greater than start date',
  path: ['end_at'],
})
